.fzf-image {
  background-image: url(../../../public/images/404-BG.JPG);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}
.main-text {
  color: #2e6da0;
  font-size: 2rem;
  font-weight: bold;
  margin-left: 10px;
}
.logo-img {
  width: 70px;
  height: 50px;
}
.error {
  font-size: 20rem;
  color: #2e6da0;
}

.back {
  color: white;
  background-color: #3572a7;
  border-radius: 40px;
  border-color: transparent;
  padding: 0.5rem 3rem;
}

@media screen and (max-width: 768px) {
  .error {
    font-size: 10rem;
  }
  .back {
    padding: 0px 5px;
  }
}

@media screen and (max-width: 1024px) {
  .back {
    padding: 0px 5px;
  }
}
